import * as React from "react"
import Layout from "../components/layout"
import ServiceTop from "../components/service-page/service-top"
import SkillsSlider from "../components/skills-slider/skills-slider"
import ServiceInfoCards from "../components/service-page/service-info-cards"
import { webshopBerles } from "../interfaces/service/services/webshop-berles"
import MetaData from "../components/metadata"
import StructuredData from "../components/structured-data"
import PageBottom from "../components/page-bottom/page-bottom"

const WebshopBerlesPage = (props) => {
  return <Layout subHeader={false}>
    <MetaData title={'Webshop bérlés'} url={props.location.href} description={"Válaszd az Alphapro havidíjas vagy forgalom arányos webshop bérlés szolgáltatását, hogy egy SEO optimalizált, nagy teherbírású, profil webáruházzal próbálhasd ki magadat."}/>
    <StructuredData title={'Webshop bérlés'} url={props.location.href} description={"Válaszd az Alphapro havidíjas vagy forgalom arányos webshop bérlés szolgáltatását, hogy egy SEO optimalizált, nagy teherbírású, profil webáruházzal próbálhasd ki magadat."}/>
    <ServiceTop service={webshopBerles} />
    <SkillsSlider />
    <ServiceInfoCards infoCards={webshopBerles.cards} />
    <PageBottom/>
  </Layout>
}

export default WebshopBerlesPage
