import { Service } from "../service-interface"
import { webshopBerlesInfoCards } from "../../service-info-card-interface/used_by/webshop-berles-cards"

export const webshopBerles : Service = {
  title: 'Webshop bérlés',
  description: "Válaszd az Alphapro havidíjas vagy forgalom arányos webshop bérlés szolgáltatását, hogy egy SEO optimalizált, nagy teherbírású, profil webáruházzal próbálhasd ki magadat.",
  icon: "/images/cloud.svg",
  topimg: "/images/webshop_berles.png",
  cards: webshopBerlesInfoCards,
  listPoints: [
    "Soha nem volt még ilyen könnyű belépni a kereskedelembe.",
    "Ne csak gondolkozzon , vágjon bele saját webáruházába.",
  ]
}
